import React, { useEffect } from "react";
import { Container, Flex } from "@radix-ui/themes";
import LoginForm from "../components/LoginForm";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, [navigate]);
  return (
    <Container width="100%" size="3">
      <Flex direction="column" align="center" gap={{ initial: "6", md: "9" }}>
        <h1 className="text-xl md:text-3xl font-bold">
          LOGIN - <span className="text-[#008B8B]">STEAM@BITS2024</span>
        </h1>

        <p className="text-[#566573] text-base md:text-lg font-semibold text-center">
          STEAM@BITS is a one-day free event for school students in Grades 9-12,
          designed to expose them to the fascinating realms of Science,
          Technology, Engineering, Arts, and Mathematics (STEAM).
        </p>

        <LoginForm />
      </Flex>
    </Container>
  );
}

export default Login;
