import { Link } from "react-router-dom";
import { Container, Flex, Grid } from "@radix-ui/themes";
import React from "react";

import Button from "../components/Button";

const Landing = () => {
  return (
    <React.Fragment>
      <Grid
        columns={{ initial: "1", md: "3" }}
        align="center"
        width="100%"
        className="absolute z-0 h-dvh top-0 brightness-50"
      >
        <img
          src="/landing/bg1.jpeg"
          alt="bg2"
          className="object-cover bg-center h-dvh hidden md:block"
        />
        <img
          src="/landing/bg2.jpeg"
          alt="bg1"
          className="object-cover bg-center h-dvh"
        />
        <img
          src="/landing/bg3.jpeg"
          alt="bg3"
          className="object-cover bg-center h-dvh hidden md:block"
        />
      </Grid>
      <Container
        size="3"
        className="absolute z-10 h-dvh w-screen flex align-center justify-center text-[#fdfdfd]"
      >
        <Grid rows="2" align="center">
          <Flex
            direction="column"
            align="center"
            justify="center"
            gap={{ initial: "3", md: "4" }}
          >
            <img
              src="/assets/logos/BITSLogoShort.png"
              alt="BITSLogo"
              width={100}
              height={100}
              className="w-[100px] md:w-[200px] h-auto"
            />

            <h1 className="text-2xl md:text-5xl font-semibold">
              BITS Pilani, Dubai Campus
            </h1>
          </Flex>

          <Flex
            direction="column"
            align="center"
            justify="center"
            gap={{ initial: "3", md: "6" }}
          >
            <h1 className="text-4xl md:text-8xl font-extrabold limelight-regular">
              STEAM@BITS2024
            </h1>

            {/* <Grid
              columns={{ initial: '1', md: '2' }}
              rows={{ initial: '2', md: '1' }}
              width='100%'
              align='center'
              justify='center'
              gap={{ initial: '3', md: '4' }}
            > */}
            <Link
              // to={'/login'}
              className="flex items-center justify-center w-full"
            >
              <Button
                title="EVENT RECAP : COMING SOON"
                className="bg-[#9F00E9] text-[#fdfdfd] w-[60%] py-3 md:py-4 text-xl rounded-3xl"
              />
            </Link>

            {/* <Link
                to={"/register"}
                className="flex items-center justify-center w-full"
              >
                <Button
                  title="REGISTER"
                  className="bg-[#9F00E9] text-[#fdfdfd] w-[60%] py-3 md:py-4 text-xl rounded-3xl"
                />
              </Link> */}
            {/* </Grid> */}
          </Flex>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

export default Landing;
