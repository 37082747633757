import CryptoJS from "crypto-js";
import { sha256 } from "crypto-hash";

import { Values } from "./values";

export const setEncryptedItem = (key, value) => {
  try {
    const stringValue = JSON.stringify(value);
    const encryptedValue = CryptoJS.AES.encrypt(
      stringValue,
      Values.encryptionKey
    ).toString();
    localStorage.setItem(key, encryptedValue);
  } catch (error) {
    console.error("Error encrypting and storing data:", error);
  }
};

export const getDecryptedItem = (key) => {
  try {
    const encryptedValue = localStorage.getItem(key);
    if (!encryptedValue) {
      return null;
    }
    const decryptedBytes = CryptoJS.AES.decrypt(
      encryptedValue,
      Values.encryptionKey
    );
    const decryptedValue = decryptedBytes.toString(CryptoJS.enc.Utf8);
    const parsedValue = JSON.parse(decryptedValue);
    return parsedValue;
  } catch (error) {
    console.error("Error decrypting and retrieving data:", error);
    return null;
  }
};

export const generateQrCodeAsDataUrl = (hashedId) => {
  var QRCode = require("qrcode");
  var qrUrl = "";
  QRCode.toDataURL(hashedId, function (err, url) {
    qrUrl = url;
  });
  return qrUrl;
};

export async function hashValue(value) {
  const localHash = await sha256(value);
  return localHash;
}

export function hasMatch(input, pattern) {
  var regex = new RegExp(pattern);
  return regex.test(input);
}
