import React from "react";
import { toast } from "sonner";
import emailjs from "emailjs-com";
import { getDownloadURL, ref } from "firebase/storage";
import { Box, Flex, Grid, AlertDialog, Button } from "@radix-ui/themes";
import {
  updateDoc,
  doc,
  arrayUnion,
  getDoc,
  arrayRemove,
} from "firebase/firestore";

import { Values } from "../helper/values";
import { firestore } from "../services/firebase";
import { storageInstance } from "../services/firebase";
import { getDecryptedItem } from "../helper/helper_functions";

const Card = ({
  id,
  imgUrl,
  title,
  time,
  description,
  venue,
  isAllDay,
  deptName,
}) => {
  return (
    <Box p={{ initial: "2", md: "4" }} width="100%" height="100%">
      <AlertDialog.Root>
        <AlertDialog.Trigger>
          <Grid
            p={{ initial: "3", xs: "4" }}
            className="h-full w-full rounded-3xl hover:scale-105 md:hover:scale-110 transition-all duration-200 ease-in cursor-pointer"
            align="end"
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url(${imgUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Flex
              align="start"
              justify="end"
              direction="column"
              className="font-semibold md:font-bold text-base md:text-xl text-[#fdfdfd] z-5 text-left text-balance gap-2"
            >
              <p className="uppercase">{title}</p>
              <p>{isAllDay === true ? "All Day" : time}</p>
            </Flex>
          </Grid>
        </AlertDialog.Trigger>
        <AlertDialog.Content className="max-w-[350px] md:max-w-[500px] flex flex-col items-center gap-2 md:gap-4 p-10 md:p-12">
          <AlertDialog.Title className="text-xl md:text-2xl text-center">
            {title}
          </AlertDialog.Title>
          <AlertDialog.Description className="text-sm md:text-base text-balance text-center">
            Department: <strong>{deptName}</strong>
            <br />
            Venue: <strong>{venue}</strong>
            <br />
            <br />
            {description}
            <br />
            <br />
            {`Are you sure you want to register for this ${
              id === "admission" ? "presentation" : "workshop"
            }?`}
          </AlertDialog.Description>

          <Flex gap="3" mt="4" justify="end">
            <AlertDialog.Cancel>
              <Button
                variant="soft"
                className="text-black px-9 bg-[#fdfdfd] rounded-lg cursor-pointer"
              >
                No
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action>
              <Button
                variant="solid"
                className="bg-[#B6554F] px-9 rounded-lg cursor-pointer"
                onClick={async () => {
                  const localDecryptedId = getDecryptedItem("id");
                  const localDecryptedEmail = getDecryptedItem("email");
                  toast.loading("Registering...");
                  // const hashedId = hashValue(localDecryptedId)
                  // const qrCodeBase64 = generateQrCodeAsDataUrl(hashedId);

                  // // Upload QR code image to Firebase Storage
                  const qrCodeRef = ref(
                    storageInstance,
                    `qr_codes/${localDecryptedId}.png`
                  );
                  // await uploadString(qrCodeRef, qrCodeBase64, "data_url");

                  // Get download URL of the uploaded QR code image
                  const qrCodeDownloadURL = await getDownloadURL(qrCodeRef);
                  const docRef = doc(
                    firestore,
                    "steamregistrations",
                    localDecryptedId
                  );

                  const docSnapshot = await getDoc(docRef);

                  if (docSnapshot.exists()) {
                    const userData = docSnapshot.data();
                    const { events } = userData;

                    if (events && events.includes(id)) {
                      toast.error("You have already registered for this event");
                      console.error(
                        "You have already registered for this event"
                      );
                      return;
                    }
                  }

                  updateDoc(docRef, {
                    events: arrayUnion(id),
                  })
                    .then(async () => {
                      const regCountFieldName = `reg_count`;
                      const steamDocRef = doc(firestore, "events", "steam");
                      const steamDocSnapshot = await getDoc(steamDocRef);
                      const existingData = steamDocSnapshot.data();
                      const currentRegCount =
                        existingData[`${id}`]["reg_count"] || 0;

                      const updatedRegCount = currentRegCount + 1;

                      const maxCount =
                        existingData[`${id}`].max_section_count || 0;
                      let timeSlotPosition = 0;
                      // if (maxCount === 0) {
                      //   console.log('from here 1');
                      //   toast('No slots available for selected workshop');
                      //   updateDoc(docRef, {
                      //     events: arrayRemove(id),
                      //   });
                      //   return;
                      // } else {
                      if (maxCount === 0) {
                        timeSlotPosition = 0;
                      } else {
                        timeSlotPosition = Math.floor(
                          updatedRegCount / maxCount
                        );
                      }
                      // }

                      if (
                        existingData[`${id}`].time_slots.length <
                        timeSlotPosition + 1
                      ) {
                        toast.error("No slots available for selected workshop");
                        updateDoc(docRef, {
                          events: arrayRemove(id),
                        });
                        return;
                      }

                      const updatedData = {
                        ...existingData,
                        [id]: {
                          ...existingData[id],
                          [regCountFieldName]: updatedRegCount,
                        },
                      };

                      const eventTimes = existingData[`${id}`].time_slots.map(
                        (slot) => {
                          const eventTimeObject = slot.toDate();
                          const eventDay = eventTimeObject.getDate();
                          const eventMonthName =
                            Values.months[eventTimeObject.getMonth()];
                          const eventYear = eventTimeObject.getFullYear();
                          const eventHours = eventTimeObject.getHours();
                          const eventMinutes = eventTimeObject.getMinutes();
                          const formattedEventMinutes =
                            eventMinutes < 10
                              ? "0" + eventMinutes
                              : eventMinutes;
                          const ampm = eventHours >= 12 ? "PM" : "AM";
                          const eventTime = `${eventDay} ${eventMonthName}, ${eventYear} - ${
                            eventHours % 12 || 12
                          }:${formattedEventMinutes} ${ampm}`;
                          return eventTime;
                        }
                      );

                      let emailSendingTime = eventTimes[timeSlotPosition];

                      if (id === "admission") {
                        emailSendingTime = eventTimes.join("\n");
                      }

                      if (isAllDay === true) {
                        emailSendingTime = "All Day";
                      }

                      await updateDoc(steamDocRef, updatedData)
                        .then(async () => {
                          console.log("Event count incremented!");
                          const formDataWithImageUrl = {
                            email: localDecryptedId,
                            title: title,
                            imageUrl: qrCodeDownloadURL,
                            to_email: localDecryptedEmail,
                            venue: venue,
                            time: emailSendingTime,
                          };
                          await emailjs.send(
                            "service_t8clwjo",
                            "template_bkml6dk",
                            formDataWithImageUrl,
                            "V2Yz1dwEESLY22zXz"
                          );
                          toast.success(
                            "Registered successfully, code sent on email"
                          );
                          console.log("Document updated successfully!");
                          console.log("Email sent successfully");
                        })
                        .catch((error) => {
                          toast.error(error);
                          console.error("Error updating count: ", error);
                        });
                    })
                    .catch((error) => {
                      toast.error(error);
                      console.error("Error updating document: ", error);
                    });
                }}
              >
                Yes
              </Button>
            </AlertDialog.Action>
          </Flex>
        </AlertDialog.Content>
      </AlertDialog.Root>
    </Box>
  );
};

export default Card;
