import React from "react";

const Input = ({ type = "text", name, value, onChange, placeholder }) => {
  return (
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      required
      className="border-2 border-[#566573] rounded-2xl text-center py-2 md:py-3 px-2 md:px-10 font-bold text-[#566573] text-xs w-full"
    />
  );
};

export default Input;
