import React from "react";
import { Container, Flex } from "@radix-ui/themes";

const Footer = () => {
  return (
    <Container
      width="100%"
      className="bg-[#fdfdfd] rounded-tl-[50px] rounded-tr-[50px] max-h-[100px] md:max-h-[200px] grid place-items-center bottom-0"
      p={{ initial: "6", md: "9" }}
    >
      <Flex direction="row" align="center" justify="between" width="100%">
        <Flex direction="row" align="center" gap="1">
          <a
            href="https://twitter.com/bitspilanidubai"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/socials/x.png"
              alt="X"
              className="w-[25px] md:w-[40px]"
            />
          </a>

          <a
            href="https://www.instagram.com/bitspilanidubai/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/socials/instagram.png"
              alt="Instagram"
              className="w-[25px] md:w-[40px]"
            />
          </a>

          <a
            href="https://www.youtube.com/channel/UClKXaO8gB10OObAkoEkyNDQ"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/socials/youtube.png"
              alt="Youtube"
              className="w-[25px] md:w-[40px]"
            />
          </a>

          <a
            href="https://www.linkedin.com/school/birla-institute-of-technology-and-science-pilani-dubai/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/socials/linkedin.png"
              alt="LinkedIn"
              className="w-[25px] md:w-[40px]"
            />
          </a>
        </Flex>
        <h1 className="text-lg md:text-2xl font-bold">STEAM@BITS2024</h1>
      </Flex>
    </Container>
  );
};

export default Footer;
