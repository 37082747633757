const Button = ({ type, title, onClick, className }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`${className} px-5 py-2 bg-[#566573] hover:bg-[#566573b0] text-[#fdfdfd] text-center font-bold rounded-2xl text-sm md:text-md cursor-pointer transition-all duration-200 ease-in`}
    >
      {title}
    </button>
  );
};

export default Button;
