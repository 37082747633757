import emailjs from "emailjs-com";
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container, Flex, Select, Grid } from "@radix-ui/themes";
import {
  doc,
  setDoc,
  query,
  getDocs,
  collection,
  where,
} from "firebase/firestore";
import { ref, uploadString, getDownloadURL } from "firebase/storage";

import Input from "./Input";
import Button from "./Button";
import { firestore, storageInstance } from "../services/firebase";
import { hashValue, setEncryptedItem } from "../helper/helper_functions";
import Loading from "./Loading";
import { Values } from "../helper/values";

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    emiratesId: "",
    schoolName: "",
    grade: "9",
    guests: "0",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "emiratesId") {
      var emiratesId = value;
      emiratesId = emiratesId.replace(/-/g, "");
      emiratesId = emiratesId.replace(/\s+/g, "");
      setFormData({ ...formData, [name]: emiratesId });
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleGradeDropDown = (grade) => {
    setFormData({ ...formData, grade });
  };

  const handleGuestsDropDown = (guests) => {
    setFormData({ ...formData, guests });
  };

  const generateQrCodeAsDataUrl = (email) => {
    var QRCode = require("qrcode");
    var qrUrl = "";
    QRCode.toDataURL(email, function (err, url) {
      qrUrl = url;
    });
    return qrUrl;
  };

  const checkDuplicateEntry = async (email, emiratesId) => {
    const q = query(
      collection(firestore, "steamregistrations"),
      where("emiratesId", "==", emiratesId)
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return false;
    } else {
      setErrorMessage(
        "A registration with the same Emirates ID already exists. Please login instead"
      );
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setErrorMessage("");

    if (!Values.emiratesIdRegex.test(formData.emiratesId)) {
      setErrorMessage("Please enter a valid Emirates ID number");
      setLoading(false);
      return;
    }

    const hashedId = await hashValue(formData.emiratesId);
    const qrCodeBase64 = generateQrCodeAsDataUrl(hashedId);

    const qrCodeRef = ref(
      storageInstance,
      `qr_codes/${formData.emiratesId}.png`
    );
    await uploadString(qrCodeRef, qrCodeBase64, "data_url");

    const qrCodeDownloadURL = await getDownloadURL(qrCodeRef);

    try {
      const formDataWithImageUrl = {
        ...formData,
        events: [],
        imageUrl: qrCodeDownloadURL,
        hashValue: hashedId,
      };
      const duplicateEntryBoolean = await checkDuplicateEntry(
        formData.email,
        formData.emiratesId
      );
      if (duplicateEntryBoolean === true) {
        setErrorMessage(
          "A registration with the same details already exists. Please login instead."
        );
        setLoading(false);
        return;
      } else {
        await setDoc(
          doc(firestore, "steamregistrations", formData.emiratesId),
          formDataWithImageUrl
        );
        await emailjs.send(
          "service_t8clwjo",
          "template_uew4rrq",
          formDataWithImageUrl,
          "V2Yz1dwEESLY22zXz"
        );
        setEncryptedItem("id", formData.emiratesId);
        setEncryptedItem("email", formData.email);

        navigate("/events", { replace: true });
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Registration failed. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loading />
      ) : (
        <Container
          width="100%"
          p={{
            initial: "6",
            md: "9",
          }}
          className="bg-[#fdfdfd] rounded-[50px]"
        >
          <form onSubmit={handleSubmit}>
            <Flex
              width="100%"
              direction="column"
              align="center"
              justify="between"
              gap={{ initial: "4", md: "5" }}
            >
              <Grid
                width="100%"
                columns={{ initial: "1", md: "2" }}
                align="center"
                gap={{ initial: "4", md: "9" }}
              >
                <Input
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Full Name"
                />

                <Input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Mobile Number"
                />
              </Grid>

              <Grid
                width="100%"
                columns={{ initial: "1", md: "2" }}
                align="center"
                gap={{ initial: "4", md: "9" }}
              >
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email ID"
                />

                <Input
                  name="emiratesId"
                  type="tel"
                  value={formData.emiratesId}
                  onChange={handleChange}
                  placeholder="Emirates ID Number"
                />
              </Grid>

              <Grid
                width="100%"
                columns={{ initial: "1", md: "2" }}
                align="center"
                gap={{ initial: "4", md: "9" }}
              >
                <Input
                  name="schoolName"
                  value={formData.schoolName}
                  onChange={handleChange}
                  placeholder="School Name"
                />

                <Grid
                  width="100%"
                  columns={{ initial: "1", md: "2" }}
                  align="center"
                  gap={{ initial: "4", md: "9" }}
                >
                  <Flex
                    width="100%"
                    direction="row"
                    align="center"
                    justify="center"
                    gap="4"
                  >
                    <p className="text-base md:text-lg font-bold text-[#566573]">
                      Grade
                    </p>

                    <Select.Root
                      defaultValue="9"
                      size="3"
                      onValueChange={handleGradeDropDown}
                      name="grade"
                    >
                      <Select.Trigger color="#566573" className="px-6 h-10" />
                      <Select.Content color="#566573">
                        <Select.Group>
                          <Select.Item value="9">9</Select.Item>
                          <Select.Item value="10">10</Select.Item>
                          <Select.Item value="11">11</Select.Item>
                          <Select.Item value="12">12</Select.Item>
                        </Select.Group>
                      </Select.Content>
                    </Select.Root>
                  </Flex>

                  <Flex
                    width="100%"
                    direction="row"
                    align="center"
                    justify="center"
                    gap="4"
                  >
                    <p className="text-base md:text-lg font-bold text-[#566573]">
                      Guests
                    </p>

                    <Select.Root
                      defaultValue="0"
                      size="3"
                      onValueChange={handleGuestsDropDown}
                      name="guests"
                    >
                      <Select.Trigger color="#566573" className="px-6 h-10" />
                      <Select.Content color="#566573">
                        <Select.Group>
                          <Select.Item value="0">0</Select.Item>
                          <Select.Item value="1">1</Select.Item>
                          <Select.Item value="2">2</Select.Item>
                          <Select.Item value="3">3</Select.Item>
                          <Select.Item value="4">4</Select.Item>
                          <Select.Item value="5">5</Select.Item>
                        </Select.Group>
                      </Select.Content>
                    </Select.Root>
                  </Flex>
                </Grid>
              </Grid>

              <p className="text-[#B6554F] text-sm md:text-md font-semibold">
                {errorMessage}
              </p>
              <Button
                type="submit"
                title="CONTINUE"
                className="px-8 md:px-16 w-full md:w-fit"
              />

              <h1 className="text-[#566573] text-base md:text-xl font-bold">
                Already Registered?{" "}
                <Link to="/login" replace>
                  <span className="italic underline">Login</span>
                </Link>
              </h1>
            </Flex>
          </form>
        </Container>
      )}
    </React.Fragment>
  );
};

export default RegisterForm;
