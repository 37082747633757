import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from "./pages/Login";
import Events from "./pages/Events";
import Landing from "./pages/Landing";
import Register from "./pages/Register";
import Layout from "./components/Layout";
import Loading from "./components/Loading";
import ProtectedRoute from "./routes/ProtectedRoute";
import UnprotectedRoute from "./routes/UnprotectedRoute";

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <UnprotectedRoute>
                <Suspense fallback={<Loading />}>
                  <Landing />
                </Suspense>
              </UnprotectedRoute>
            }
          />
          <Route
            path="/register"
            element={
              <Suspense fallback={<Loading />}>
                <UnprotectedRoute>
                  <Layout>
                    <Register />
                  </Layout>
                </UnprotectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/login"
            element={
              <Suspense fallback={<Loading />}>
                <UnprotectedRoute>
                  <Layout>
                    <Login />
                  </Layout>
                </UnprotectedRoute>
              </Suspense>
            }
          />
          <Route
            path="/events"
            element={
              <Suspense fallback={<Loading />}>
                <ProtectedRoute>
                  <Layout>
                    <Events />
                  </Layout>
                </ProtectedRoute>
              </Suspense>
            }
          />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
