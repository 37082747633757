import React from 'react';
import { Flex, Grid } from '@radix-ui/themes';

import Card from './Card';

const Day = ({ date, events }) => {
  return (
    <Flex
      width='100%'
      direction='column'
      align='center'
      gap={{ initial: '6', md: '8' }}
    >
      <Grid
        columns={{ initial: '2', xs: '3', md: '4' }}
        rows={{ initial: '5', xs: '5', md: '4' }}
        width='100%'
        align='center'
        justify='center'
        gap={{ initial: '0', xs: '0', md: '1' }}
        className='pb-3'
      >
        {events.map((event) => (
          <Card
            key={event.id}
            id={event.id}
            imgUrl={event.imageUrl}
            description={event.description}
            title={event.title.replace('\\n', '\n')}
            venue={event.venue}
            deptName={event.deptName}
            isAllDay={event.isAllDay}
            time={new Date(event.time_slots[0] * 1000).toLocaleTimeString([], {
              hour: '2-digit',
              minute: '2-digit',
            })}
          />
        ))}
      </Grid>
    </Flex>
  );
};

export default Day;
