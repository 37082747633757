import { Container, Flex } from "@radix-ui/themes";
import React, { Suspense, useEffect, useState } from "react";
import { collection, onSnapshot, query } from "firebase/firestore";

import Day from "../components/Day";
import Loading from "../components/Loading";
import { firestore } from "../services/firebase";

function Events() {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      try {
        const eventsCollection = collection(firestore, "events");

        const unsubscribeEvents = onSnapshot(
          query(eventsCollection),
          async (snapshot) => {
            const updatedEvents = await Promise.all(
              snapshot.docs.map(async (doc) => {
                const data = doc.data();
                return data;
              })
            );

            const finalEvents = [];

            Object.values(updatedEvents[0]).forEach((updatedEvent) => {
              finalEvents.push(updatedEvent);
            });

            finalEvents.sort((event1, event2) => {
              const timestamp1 = event1.time_slots[0].seconds;
              const timestamp2 = event2.time_slots[0].seconds;

              if (timestamp1 < timestamp2) {
                return -1;
              } else if (timestamp1 > timestamp2) {
                return 1;
              } else {
                return 0;
              }
            });

            setEvents(finalEvents);
          }
        );
        return () => {
          unsubscribeEvents();
        };
      } catch (error) {
        console.error("Error fetching events : ", error);
      }
    };
    fetchData();
  }, []);

  return (
    <Container size="4">
      <Flex
        direction="column"
        align="center"
        gap={{ initial: "6", md: "9" }}
        className="text-center"
      >
        <h1 className="text-xl md:text-3xl font-bold">
          EVENTS - <span className="text-[#008B8B]">STEAM@BITS2024</span>
        </h1>

        <p className="text-[#566573] text-base md:text-lg font-semibold">
          Come and Experience Exciting events at STEAM@BITS2024!
          <br />
          <br /> The activities commence at 9 AM and will run for the entire day
          till 4 PM. Students are welcome to attend with their parents or
          teachers.
          <br />
          Students can expect events such as{" "}
          <strong>
            Challenging Scientific and Management Games, Hands on experience
            with latest tools and techniques, Engineering & Art Workshops
          </strong>{" "}
          and much more!
          <br />
          <br />
          All participating students will receive a certificate and the students
          will have an opportunity to win several prizes.
        </p>

        <Flex direction="column" align="center" gap={{ initial: "4", md: "6" }}>
          <h1 className="text-xl md:text-3xl font-bold">
            WORKSHOP REGISTRATIONS
          </h1>

          <p className="text-[#566573] text-base md:text-lg font-semibold">
            Please select and register for the workshops you would like to
            attend. Upon successful registration, you will recieve an email with
            your pass for the same.
          </p>

          <Suspense fallback={<Loading />}>
            {events && events.length !== 0 && (
              <Day date="27 MARCH" events={events} />
            )}
          </Suspense>
        </Flex>
      </Flex>
    </Container>
  );
}

export default Events;
