import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCakYq9u9RbzJyPkYdF8FOR-a9vDm3ApfQ",
  authDomain: "bpdc-jashn.firebaseapp.com",
  projectId: "bpdc-jashn",
  storageBucket: "bpdc-jashn.appspot.com",
  messagingSenderId: "212702911080",
  appId: "1:212702911080:web:07ecb26f80e0391db039dd",
  measurementId: "G-B5RZG29B90",
};

export const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
export const storageInstance = getStorage(app);
export const analytics = getAnalytics(app);
