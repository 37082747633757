import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Container, Flex, Grid } from "@radix-ui/themes";
import { query, getDocs, collection, where } from "firebase/firestore";
import { Values } from "../helper/values";
import Input from "./Input";
import Button from "./Button";
import Loading from "./Loading";
import { firestore } from "../services/firebase";
import { setEncryptedItem } from "../helper/helper_functions";

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: "",
    emiratesId: "",
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "emiratesId") {
      var emiratesId = value;
      emiratesId = emiratesId.replace(/-/g, "");
      emiratesId = emiratesId.replace(/\s+/g, "");
      setFormData({ ...formData, [name]: emiratesId });
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const checkLogin = async (email, emiratesId) => {
    const q = query(
      collection(firestore, "steamregistrations"),
      where("emiratesId", "==", emiratesId)
    );

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return false;
    }

    for (const doc of querySnapshot.docs) {
      const data = doc.data();

      if (data.email === email) {
        return true;
      }

      return false;
    }

    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    setErrorMessage("");

    if (!Values.emiratesIdRegex.test(formData.emiratesId)) {
      setErrorMessage("Please enter a valid Emirates ID number");
      setLoading(false);
      return;
    }

    try {
      const checkLoginBool = await checkLogin(
        formData.email,
        formData.emiratesId
      );
      if (checkLoginBool === true) {
        setEncryptedItem("id", formData.emiratesId);
        setEncryptedItem("email", formData.email);
        navigate("/events", { replace: true });
        return;
      } else {
        setErrorMessage("Invalid Email and Emirates ID combination");
        return;
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Registration failed. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <Loading />
      ) : (
        <Container
          width="100%"
          p={{
            initial: "6",
            md: "9",
          }}
          className="bg-[#fdfdfd] rounded-[50px]"
        >
          <form onSubmit={handleSubmit}>
            <Flex
              direction="column"
              align="center"
              justify="between"
              gap={{ initial: "4", md: "5" }}
            >
              <Grid
                width="100%"
                columns={{ initial: "1", md: "2" }}
                align="center"
                gap={{ initial: "4", md: "9" }}
              >
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email ID"
                />

                <Input
                  name="emiratesId"
                  type="tel"
                  value={formData.emiratesId}
                  onChange={handleChange}
                  placeholder="Emirates ID Number"
                />
              </Grid>

              <p className="text-[#B6554F] text-sm md:text-md font-semibold">
                {errorMessage}
              </p>
              <Button
                type="submit"
                title="LOGIN"
                className="px-8 md:px-16 w-full md:w-fit"
              />

              <h1 className="text-[#566573] text-base md:text-xl font-bold">
                Don't have an account?{" "}
                <Link to="/register" replace>
                  <span className="italic underline">Register</span>
                </Link>
              </h1>
            </Flex>
          </form>
        </Container>
      )}
    </React.Fragment>
  );
};

export default LoginForm;
