import React, { useEffect } from "react";
import { Container, Flex } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";
import RegisterForm from "../components/RegisterForm";

function Register() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, [navigate]);
  return (
    <Container width="100%" size="3">
      <Flex direction="column" align="center" gap={{ initial: "6", md: "9" }}>
        <h1 className="text-xl md:text-3xl font-bold text-center">
          REGISTER - <span className="text-[#008B8B]">STEAM@BITS2024</span>
        </h1>

        <Flex
          direction="column"
          align="center"
          gap={{ initial: "4", md: "5" }}
          className="text-[#566573] text-base md:text-lg font-semibold text-center"
        >
          <p>
            STEAM@BITS is a one-day free event for school students in Grades
            9-12, designed to expose them to the fascinating realms of Science,
            Technology, Engineering, Arts, and Mathematics (STEAM).
          </p>
          <p>
            To enroll for this event, kindly complete the form below with
            accurate details. Be assured that your information is treated with
            utmost confidentiality and is solely utilized for event-related
            correspondence and verification purposes only. Upon successful
            registration, you will receive a confirmation email containing your
            unique QR pass for event entry.
          </p>
          <p>No separate registration required for parents or guardians.</p>
        </Flex>

        <RegisterForm />
      </Flex>
    </Container>
  );
}

export default Register;
